import React, { useState, useEffect } from "react";
import "./NavBar.css";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa"; // Import X icon

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="TopArea">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "0vh",
            padding: "3%"
          }}
        >
          {!showMenu && (
            <div className="logo-container">
              <strong className="logo-text">Atlas Pont</strong>
              <img
                src="/media/pics/logo-black.png"
                alt="Logo"
                className="logo-image"
              />
            </div>
          )}
          <div className="menu-container">
            <div className={`menu ${showMenu ? "show" : ""}`}>
              <Link to="/" className="underLine2 hide_on_responsive">
                <Button
                  variant="text"
                  color="default"
                  onClick={closeMenu} // Add this line to close the menu on click
                >
                  MAISON
                </Button>
              </Link>
              <Link
                to="/products"
                className="underLine2 hide_on_responsive"
                href=""
              >
                <Button
                  variant="text"
                  color="default"
                  onClick={closeMenu} // Add this line to close the menu on click
                >
                  PRODUITS
                </Button>
              </Link>
              <Link
                to="/clients"
                className="underLine2 hide_on_responsive"
                href=""
              >
                <Button
                  variant="text"
                  color="default"
                  onClick={closeMenu} // Add this line to close the menu on click
                >
                  CLIENTS
                </Button>
              </Link>
              <Link
                to="/about"
                className="underLine2 hide_on_responsive"
                href=""
              >
                <Button
                  variant="text"
                  color="default"
                  onClick={closeMenu} // Add this line to close the menu on click
                >
                  PROPOS
                </Button>
              </Link>
              <Link to="/contact" className="underLine2">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={closeMenu} // Add this line to close the menu on click
                >
                  CONTACT
                </Button>
              </Link>
            </div>
            <div className="menu-icon" onClick={toggleMenu}>
              {showMenu ? <FaTimes size={24} /> : <FaBars size={24} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
