// Home.js
import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Chest  from '../Chest';

function Home() {
  return (
    <>
      <HeroSection 
        title="Atlas Pont"
        description="Jouets Mondiaux: Sélectionnés pour la Joie, Ciblé sur le Cerveau."
      />
      <Chest description={"Nous sommes une entreprise dédiée à fournir des outils de pointe pour le diagnostic initial des maladies des enfants, notamment le TDAH, les problèmes de dextérité et les problèmes de coordination cerveau-muscle. Notre objectif est d’améliorer ces domaines en stimulant les capacités cognitives, garantissant ainsi une génération future plus saine et plus robuste."}/>
    </>
  );
}

export default Home;