// ProductsData.js
const ProductsData = [
    {
      id: 1,
      name: 'Alphabet Puzzle',
      description: "Découvrez notre Puzzle en Bois Alphabet Majuscule à Prise Manuelle! Idéal pour la maternelle et le jardin d'enfants, ce tableau interactif présente des lettres vives pour un apprentissage précoce amusant. Regardez les tout-petits saisir, tourner et apprendre. Parfait pour jouer tout en développant les compétences en littérature précoce! Conçu pour les enfants de 1 à 4 ans, ce produit améliore la dextérité et les compétences motrices d'un enfant, mettant l'accent sur ses capacités manuelles.",
      prices: {
        '5-9': 5.00,
        '10-99': 4.50,
        '100+': 4.00
      },
      images: [
        '/media/pics/products/1-1.webp',
        '/media/pics/products/1-2.webp',
        '/media/pics/products/1-3.webp',
        '/media/pics/products/1-4.webp',
        '/media/pics/products/1-5.webp'
      ]
    },
    {
      id: 2,
      name: 'Tangram Puzzle',
      description: "Ce package respectueux de l'environnement comprend cinq pièces en bois (de taille 3x2x17 cm) et est disponible en cinq couleurs délicieuses, telles qu'illustrées dans l'image. Il est spécialement conçu pour les enfants âgés de 1 à 9 ans. Un puzzle conçu pour stimuler les facultés cognitives des enfants, sollicitant divers aspects de leur cerveau.",
      prices: {
        '5-9': 6.00,
        '10-99': 5.50,
        '100+': 5.00
      },
      images: [
        '/media/pics/products/2-1.webp',
        '/media/pics/products/2-2.webp',
        '/media/pics/products/2-3.webp',
        '/media/pics/products/2-4.webp',
        '/media/pics/products/2-5.webp'
      ]
    },
    {
        id: 3,
        name: 'Animal Puzzle',
        description: "Libérez l'apprentissage et le plaisir avec notre Puzzle en Bois Animaux pour Enfants ! Ces puzzles 3D Montessori favorisent la créativité et les compétences cognitives!",
        prices: {
          '5-9': 6.00,
          '10-99': 5.50,
          '100+': 5.00
        },
        images: [
          '/media/pics/products/3-1.webp',
          '/media/pics/products/3-2.webp',
          '/media/pics/products/3-3.webp',
          '/media/pics/products/3-4.webp',
          '/media/pics/products/3-5.webp',
          '/media/pics/products/3-6.webp',
          '/media/pics/products/3-7.webp',
          '/media/pics/products/3-8.webp',
          '/media/pics/products/3-9.webp',
          '/media/pics/products/3-10.webp',
          '/media/pics/products/3-11.webp',
          '/media/pics/products/3-12.webp',
          '/media/pics/products/3-13.webp',
          '/media/pics/products/3-14.webp',
          '/media/pics/products/3-15.webp',
          '/media/pics/products/3-16.webp',
          '/media/pics/products/3-17.webp',
          '/media/pics/products/3-18.webp',
          '/media/pics/products/3-19.webp',
          '/media/pics/products/3-20.webp',
          '/media/pics/products/3-21.webp',
          '/media/pics/products/3-22.webp',
          '/media/pics/products/3-23.webp',
          '/media/pics/products/3-24.webp'
        ]
      },
      {
        id: 4,
        name: 'Geometric Puzzle',
        description: "Plongez dans la joie de l'apprentissage avec nos Jouets en Gros de Puzzle Géométrique 3D pour Enfants! Ces puzzles en bois inspirent la créativité et développent les compétences cognitives. Un indispensable pour chaque petit apprenant!",
        prices: {
          '5-9': 6.00,
          '10-99': 5.50,
          '100+': 5.00
        },
        images: [
          '/media/pics/products/4-1.webp',
          '/media/pics/products/4-2.webp',
          '/media/pics/products/4-3.webp',
          '/media/pics/products/4-4.webp',
          '/media/pics/products/4-5.webp',
          '/media/pics/products/4-6.webp',
          '/media/pics/products/4-7.webp',
          '/media/pics/products/4-8.webp',
          '/media/pics/products/4-9.webp',
          '/media/pics/products/4-10.webp',
          '/media/pics/products/4-11.webp'
        ]
      },
      {
        id: 5,
        name: 'Animal Jungle (2D shape)',
        description: "Partez à la découverte avec notre Tableau des Habitats des Animaux ! Inspiré par le Dr. Russll Barkley, ce jeu incite les enfants à associer les animaux à leur environnement naturel. Des lions du désert aux pingouins polaires, c'est une façon amusante d'apprendre sur la faune et la géographie.",
        prices: {
          '5-9': 6.00,
          '10-99': 5.50,
          '100+': 5.00
        },
        images: [
            '/media/pics/products/5-1.webp',
            '/media/pics/products/5-2.webp',
            '/media/pics/products/5-3.webp',
            '/media/pics/products/5-4.webp',
            '/media/pics/products/5-5.webp',
            '/media/pics/products/5-6.webp',
            '/media/pics/products/5-7.webp',
            '/media/pics/products/5-8.webp',
            '/media/pics/products/5-9.webp',
            '/media/pics/products/5-10.webp',
            '/media/pics/products/5-11.webp',
            '/media/pics/products/5-12.webp',
            '/media/pics/products/5-13.webp',
            '/media/pics/products/5-14.webp',
            '/media/pics/products/5-15.webp',
            '/media/pics/products/5-16.webp',
            '/media/pics/products/5-17.webp',
            '/media/pics/products/5-18.webp',
            '/media/pics/products/5-19.webp',
            '/media/pics/products/5-20.webp',
            '/media/pics/products/5-21.webp',
            '/media/pics/products/5-22.webp',
            '/media/pics/products/5-23.webp',
            '/media/pics/products/5-24.webp',
            '/media/pics/products/5-25.webp'
        ]
      },
      {
        id: 6,
        name: 'Shape Puzzle (Tangram 2)',
        description: "Découvrez notre collection polyvalente de jouets éducatifs en bois pour enfants, comprenant des jeux interactifs, des ensembles de boîtes en fer à thème de dessins animés, des blocs de construction, des puzzles captivants, et le défi améliorant les capacités cérébrales de faire passer le fil à travers les blocs. Ces jouets de haute qualité offrent le parfait équilibre entre divertissement et apprentissage, stimulant la créativité et la résolution de problèmes tout en gardant les enfants captivés pendant des heures.",
        prices: {
          '5-9': 6.00,
          '10-99': 5.50,
          '100+': 5.00
        },
        images: [
          '/media/pics/products/6-1.webp',
          '/media/pics/products/6-2.webp',
          '/media/pics/products/6-3.webp',
          '/media/pics/products/6-4.webp',
          '/media/pics/products/6-5.webp',
          '/media/pics/products/6-6.webp'
        ]
      },
      {
        id: 7,
        name: 'Spike Game',
        description: "Stimulez l'éducation précoce de votre enfant avec nos Jouets en Bois pour Correspondance d'Enfants. Ce jeu incite les enfants à manœuvrer et à adapter des formes colorées dans leurs emplacements désignés, renforçant ainsi leur cognition des formes tout en favorisant la créativité.",
        prices: {
          '5-9': 6.00,
          '10-99': 5.50,
          '100+': 5.00
        },
        images: [
          '/media/pics/products/7-1.webp',
          '/media/pics/products/7-2.webp',
          '/media/pics/products/7-3.webp',
          '/media/pics/products/7-4.webp',
          '/media/pics/products/7-5.webp',
          '/media/pics/products/7-6.webp'
        ]
      },
      {
        id: 8,
        name: 'Magic Ball',
        description: "La Magic Rainbow Ball est un passionnant jouet puzzle en plastique avec 12 trous. Mettez au défi les compétences de résolution de problèmes de votre enfant alors qu'ils naviguent dans le labyrinthe pour faire correspondre les couleurs. Ce jouet n'est pas simplement amusant ; c'est une aventure stimulante pour le cerveau qui renforce les compétences cognitives et offre un divertissement sans fin pour les esprits curieux.",
        prices: {
          '5-9': 6.00,
          '10-99': 5.50,
          '100+': 5.00
        },
        images: [
          '/media/pics/products/8-1.webp',
          '/media/pics/products/8-2.webp',
          '/media/pics/products/8-3.webp',
          '/media/pics/products/8-4.webp',
          '/media/pics/products/8-5.webp',
          '/media/pics/products/8-6.webp'
        ]
      },
      {
        id: 9,
        name: 'Stress Spiner',
        description: "Découvrez notre dernière nouveauté : le Fidget Spinner EDC en métal. Fabriqué en cuivre pur ou en aluminium, ce jouet anti-stress est idéal pour occuper les doigts agités. Il est livré avec une élégante boîte en métal pour la portabilité et la sécurité. Rehaussez votre concentration et votre relaxation en déplacement avec ce spinner moderne.",
        prices: {
          '5-9': 6.00,
          '10-99': 5.50,
          '100+': 5.00
        },
        images: [
          '/media/pics/products/9-1.webp',
          '/media/pics/products/9-2.webp',
          '/media/pics/products/9-3.webp',
          '/media/pics/products/9-4.webp',
          '/media/pics/products/9-5.webp',
          '/media/pics/products/9-6.webp'
        ]
      },
      {
        id: 10,
        name: 'Magnetic Board',
        description: "Découvrez nos jouets en bois pour enfants, une expérience éducative et divertissante pour les enfants à partir de 3 ans. Il s'agit d'un jeu interactif où ils peuvent jouer ensemble, favorisant le travail d'équipe et le renforcement de leurs compétences en résolution de problèmes. Ce jeu n'est pas uniquement destiné aux enfants, il offre également une merveilleuse occasion aux parents de renforcer leurs liens avec leurs enfants, en faisant de cette activité en famille un moment privilégié pour consolider la relation parent-enfant.",
        prices: {
          '5-9': 6.00,
          '10-99': 5.50,
          '100+': 5.00
        },
        images: [
          '/media/pics/products/10-1.webp',
          '/media/pics/products/10-2.webp',
          '/media/pics/products/10-3.webp',
          '/media/pics/products/10-4.webp',
          '/media/pics/products/10-5.webp',
          '/media/pics/products/10-6.webp',
          '/media/pics/products/10-7.webp',
          '/media/pics/products/10-8.webp',
          '/media/pics/products/10-9.webp',
          '/media/pics/products/10-10.webp'
        ]
      },
    // Add more products as needed
  ];
  
  export default ProductsData;
  