// HeroSection.js
import React from 'react';
import './HeroSection.css';

function HeroSection({ title, description, email }) {
  return (
    <div className='hero-container'>
      <h1>{title}</h1>
      <p>{description}{email && (<a href={`mailto:${email}`}>{email}</a>)}</p>
    </div>
  );
}

export default HeroSection;
