// CardItem.js 
import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {

  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
          <figure className='cards__item__pic-wrap'>
            <img className='cards__item__img' alt='Static' src={props.src_img}/>
          </figure>
          <div className='cards__item__info'>
            {/* <h5 className='cards__item__title'>{props.title}</h5> */}
            <div className='cards__item__title'>{props.title}</div>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
