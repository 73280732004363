// SingleProduct.js
import React, { useState, useEffect } from 'react';
import './SingleProduct.css';
import ProductsData from './ProductsData';
import { useParams } from 'react-router-dom';

const SingleProduct = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        const product = ProductsData.find(product => product.id === parseInt(productId));
        if (product) {
            setProduct(product);
            setSelectedImage(product.images[0]);
            window.scrollTo(0, 0);
        }
    }, [productId]);

    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    }

    const handleArrowClick = (direction) => {
        const currentIndex = product.images.indexOf(selectedImage);
        let newIndex;
    
        if (direction === 'next') {
            newIndex = currentIndex === product.images.length - 1 ? 0 : currentIndex + 1;
        } else if (direction === 'prev') {
            newIndex = currentIndex === 0 ? product.images.length - 1 : currentIndex - 1;
        }
    
        setSelectedImage(product.images[newIndex]);
    
        // Shift the list of thumbnail images
        const thumbnails = document.querySelectorAll('.thumbnail-images img');
        thumbnails.forEach((thumbnail, index) => {
            const newIndex = (currentIndex + (product.images.length-2) + index + (direction === 'next' ? 1 : -1)) % product.images.length;
            thumbnail.src = product.images[newIndex];
        })
    }

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <div className="product-box">
            <div className="single-product">
                <div className="product-images">
                    <div className="image-container">
                        <img src={selectedImage} alt={product.name} />
                    </div>
                    <div className="thumbnail-images">
                        <span className="arrow arrow-prev" onClick={() => handleArrowClick('prev')}><i className="fas fa-chevron-left"></i></span>
                        {product.images.slice(-2).concat(product.images.slice(0, 3)).map((image, index) => (
                            <img
                            key={index}
                            src={image}
                            alt={product.name}
                            className={index === 2 ? 'middle' : ''}
                            />
                            ))}
                        <span className="arrow arrow-next" onClick={() => handleArrowClick('next')}><i className="fas fa-chevron-right"></i></span>
                    </div>
                </div>
                <div className="product-info">
                    <h2>{product.name}</h2>
                    <hr />
                    <p>{product.description}</p>
                    <hr />
                    <div className="price-options">
                        <div className="price-option">
                            {/* <strong>5-9 pieces:</strong> ${product.prices['5-9'].toFixed(2)} */}
                            <strong>5-9 pieces: TBA</strong>
                        </div>
                        <div className="price-option">
                            {/* <strong>10-99 pieces:</strong> ${product.prices['10-99'].toFixed(2)} */}
                            <strong>10-99 pieces: TBA</strong>
                        </div>
                        <div className="price-option">
                            {/* <strong>100+ pieces:</strong> ${product.prices['100+'].toFixed(2)} */}
                            <strong>100+ pieces: TBA</strong>
                        </div>
                    </div>
                    <hr />
                    <div className="quantity-selector">
                        <label htmlFor="quantity">Quantity:  </label>
                        <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            min="1"
                            value={quantity}
                            onChange={handleQuantityChange}
                            style={{ width: '50px', borderRadius: '5px', padding: '5px' }}
                        />
                    </div>
                    <hr />
                    <button className="contact-button" style={{ width: '48%', marginRight: '2%' }}>Contact Client</button>
                    {/* <button className="contact-button" style={{ width: '48%' }}>Contact Us</button> */}
                    <button className="contact-button" style={{ width: '48%' }} onClick={() => window.location.href = 'mailto:info@atlasbridge.ca'}>
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SingleProduct;
