import React from 'react';
import './Chest.css';

function Chest({ description }) {
  return (
    <div className="chest-section">
        <p>{description}</p>
      </div>
  );
}

export default Chest;
