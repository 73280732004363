// Footer.js
import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>À Propos</h2>
            <Link to='/'>Comment ça fonctionne</Link>
            <Link to='/'>Investisseurs</Link>
            <Link to='/'>Conditions d'utilisation</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact</h2>
            <Link to='/contact'>Contact</Link>
            <Link to='/'>Soutien</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Parrainages</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Des Produits</h2>
            <Link to='/'>Soumettre le produit</Link>
            <Link to='/'>Agence</Link>
            <Link to='/'>Influenceur</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Réseaux Sociaux</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              Atlas Pont
              <img
                src="/media/pics/logo-white.png"
                alt="Logo"
                style={{ height: '30px', marginLeft: '10px' }}
              />
            </Link>
          </div>
          <small class='website-rights'>Arman Hafizi © 2023</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            {/* <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
