import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {

  return (
    <div className='cards'>
      <div className='cards__container' style={{ backgroundImage: `url(images/bg-2.jpg)`, backgroundSize: 'cover' }}>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
                src_img='/media/pics/products/1-1.webp'
                title='Alphabet Puzzle'
                path='/products/1'
            />
            <CardItem
                src_img='/media/pics/products/2-1.webp'
                title='Tangram Puzzle'
                path='/products/2'
            />
            <CardItem
                src_img='/media/pics/products/3-1.webp'
                title='Animal Puzzle'
                path='/products/3'
            />
            <CardItem
                src_img='/media/pics/products/4-1.webp'
                title='Geometric Puzzle'
                path='/products/4'
            />
            <CardItem
                src_img='/media/pics/products/5-1.webp'
                title='Animal Jungle (2D shape)'
                path='/products/5'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
                src_img='/media/pics/products/6-1.webp'
                title='Shape Puzzle (Tangram 2)'
                path='/products/6'
            />
            <CardItem
                src_img='/media/pics/products/7-1.webp'
                title='Spike Game'
                path='/products/7'
            />
            <CardItem
                src_img='/media/pics/products/8-1.webp'
                title='Magic Ball'
                path='/products/8'
            />
            <CardItem
                src_img='/media/pics/products/9-1.webp'
                title='Stress Spiner'
                path='/products/9'
            />
            <CardItem
                src_img='/media/pics/products/10-1.webp'
                title='Magnetic Board'
                path='/products/10'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
