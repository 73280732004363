// Products.js
import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';

function Products() {
  return (
    <>
      <HeroSection 
        title="Des Produits"
        description="Jouets Mondiaux: Sélectionnés pour la Joie, Livrés à l'Échelle Internationale."
      />
      <Cards />
    </>
  );
}

export default Products;