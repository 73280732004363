// App.js
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Products from './components/pages/Products';
import Contact from './components/pages/Contact';
import Clients from './components/pages/Clients';
import About from './components/pages/About';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import SingleProduct from './components/SingleProduct';

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <NavBar />
          <div className="content">
            <Routes>
              <Route path='/' exact element={<Home/>} />
              <Route path='/products' element={<Products/>} />
              <Route path='/products/:productId' element={<SingleProduct/>} />
              <Route path='/clients' exact element={<Clients/>} />
              <Route path='/contact' exact element={<Contact/>} />
              <Route path='/about' exact element={<About/>} />
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
