import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

function About() {
  return (
    <>
      <HeroSection 
        title="À Propos"
        description="Jouets Mondiaux: Sélectionnés pour la Joie, Livrés à l'Échelle Internationale."
      />
    </>
  );
}

export default About;