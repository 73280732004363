import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

function Contact() {
  return (
    <>
      <HeroSection 
        title="Contact"
        description="contactez-nous à "
        email="info@atlasbridge.ca"
      />
    </>
  );
}

export default Contact;