import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

function Clients() {
  return (
    <>
      <HeroSection 
        title="Clients"
        description="Jouets Mondiaux: Sélectionnés pour la Joie, Livrés à l'Échelle Internationale."
      />
    </>
  );
}

export default Clients;